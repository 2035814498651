import BaseComponent from "../../BaseComponent";

export default class CommunicationTable extends BaseComponent {

    private readonly $popoverEls = [];

    init() {
        this.$component.find('[data-has-popover]').each( (i, el) => {
            const $el = $(el);
            this.$popoverEls.push($el);
            this.resetPopover($el);

            $el.on('click', () => {
                this.$popoverEls.forEach($pel => $pel !== $el && $pel.popover('hide'));
                const url = $el.find('[data-url]').first().data('url');
                if (!url) {
                    return;
                }
                this.context.naja.makeRequest('get', url)
                    .then(() => {
                        this.resetPopover($el).popover('show');
                    });
            })

        });
    }

    private resetPopover($el: JQuery): JQuery {
        $el.popover('dispose');

        $el.popover({
            trigger: 'click',
            html: true,
            content: () => {
                return $el.find('popover').first().html();
            },
        });

        return $el;
    }
}