/* 1. IMPORT GLOBAL FILES */
const imagesContext = require.context('./images', true, /\.(png|jpg|jpeg|gif|ico|svg|webp)$/);
imagesContext.keys().forEach(imagesContext);


import "bootstrap";
import "bootstrap-table";
import "bootstrap-select";
import "happy-inputs";
import naja from 'naja';

import LiveForm from './extensions/LiveForm';
import { Nette } from 'live-form-validation';
import { ComponentFactory, ComponentsType } from './components/ComponentFactory';
import ExtensionRegistrar from './extensions/ExtensionRegistrar';

/* 2. IMPORT COMPONENTS */
import CodeMirror from "./components/CodeMirror";
import ClassToggler from "./components/ClassToggler";
import Appearance from "./components/Appearance";
import OpenModal from "./components/OpenModal";
import HtmlPreview from "./components/HtmlPreview";
import MarkdownEditor from "./components/MarkdownEditor";
import CommunicationTable from "./components/CommunicationTable";
import ProjectDashboard from "./components/ProjectDashboard";
import ShowImage from "./components/ShowImage/js/ShowImage";
import TagsAssignment from "./components/tags/TagsAssignment";
import TagsFavourite from "./components/tags/TagsFavourite";
import TagsManagement from "./components/tags/TagsManagement";


/* 3. IMPORT EXTENSIONS */
import ClickTarget from "./extensions/ClickTarget";
import ChangeValueTarget from "./extensions/ChangeValueTarget";
import FormLoad from "./extensions/FormLoad";
import DependSelectBox from "./extensions/Forms/DependSelectBox";
import Select2 from "./extensions/Select2";
import Datepicker from "./extensions/Datepicker";
import CopyToClipboard from "./extensions/CopyToClipboard";
import OnLoadDoAjax from "./extensions/OnLoadDoAjax";
import "./extensions/datagrid";

/* 4. Configure Sentry */
const Raven = require('raven-js');
if (typeof window.raven !== 'undefined' && process.env.NODE_ENV === 'production') {
    Raven.config(window.raven).install();
} else {
    console.log('Raven is not initialized in DEV mode');
}

/* 5. INIT PAGE FUNCTION */
declare global {
    interface Window {
        Nette: { init: () => void, validateForm: (sender, onlyCheck) => boolean },
        LiveForm: LiveForm,
        FB: { init: (...arg: any[]) => void, ui: (...arg: any[]) => boolean },
        raven: string,
        $: JQueryStatic,
        jQuery: JQueryStatic,
    }
}

window.Nette = Nette;
window.LiveForm = LiveForm;

document.addEventListener('DOMContentLoaded', () => {
    naja.initialize();
});


/* 6. Register components */
export const components: ComponentsType = {};
components.CodeMirror = CodeMirror;
components.ClassToggler = ClassToggler;
components.Appearance = Appearance;
components.OpenModal = OpenModal;
components.HtmlPreview = HtmlPreview;
components.MarkdownEditor = MarkdownEditor;
components.CommunicationTable = CommunicationTable;
components.ProjectDashboard = ProjectDashboard;
components.ShowImage = ShowImage;
components.TagsAssignment = TagsAssignment;
components.TagsFavourite = TagsFavourite;
components.TagsManagement = TagsManagement;

/* 7. Register components as naja extension */
ExtensionRegistrar.addExtension(new ComponentFactory(components));

/* 8. Register extensions */
ExtensionRegistrar.addExtension(new ClickTarget());
ExtensionRegistrar.addExtension(new ChangeValueTarget());
ExtensionRegistrar.addExtension(new FormLoad());
ExtensionRegistrar.addExtension(new DependSelectBox());
ExtensionRegistrar.addExtension(new Select2());
ExtensionRegistrar.addExtension(new Datepicker());
ExtensionRegistrar.addExtension(new CopyToClipboard());
ExtensionRegistrar.addExtension(new OnLoadDoAjax());

ExtensionRegistrar.register();

/* 9. Styles */
import './app.scss';

$(function () {
    Nette.addEvent = function(element, on, callback) {
        $(element).on(on, callback);
    };
});