// @ts-ignore
import CodeMirror from 'codemirror';
import IEditor from "./IEditor";

require("codemirror/mode/htmlmixed/htmlmixed");

export default class HtmlEditor implements IEditor {
    public onChange?: ()=> {};
    public editor: CodeMirror;

    constructor(
        public readonly element: HTMLTextAreaElement
    ) {
        this.init();
    }

    private init(): void {
        this.editor = CodeMirror.fromTextArea(this.element, {
            lineNumbers: true,
            mode: "htmlmixed",
            lineWrapping: false,
            htmlMode: true,
            matchClosing: true,
            indentWithTabs: true,
            viewportMargin: Infinity,
            readOnly: this.element.hasAttribute('readonly'),
        });

        const elementJQuery = $(this.element);
        const height = elementJQuery.data('editor-height');

        this.editor.setSize("100%", height ? height : 300);
        this.editor.on('change', () => {
            this.editor.save(); // good or not?
            this.onChange?.();
        });
    }
}
