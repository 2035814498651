import BaseComponent from "../../BaseComponent";

export default class OpenModal extends BaseComponent {
    init() {
        this.$component.on('click', () => {
            const target = this.$component.data('modal-target');
            if (target) {
                const $target = $(target);
                if ($target.length) {
                    $target.find('.modal-body').text('...');
                    $target.find('.modal-title').text('...');
                    $(target).modal('show');
                }
            }
        });
    }
}
