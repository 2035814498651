import BaseComponent from "../../BaseComponent";

export default class HtmlPreview extends BaseComponent {

    private source;
    private lastContent: string;

    init() {

        const interval = this.$component.data('refresh-interval')

        this.source = $(this.$component.data('watch-source'));

        setInterval(
            () => {
                const currentContent = this.source.val();

                if (this.lastContent !== currentContent) {
                    this.$component.addClass('reload');
                    this.$component.attr("srcdoc", currentContent);
                    this.lastContent = currentContent;
                    this.$component.removeClass('reload');
                    this.$component.trigger('blur');
                }
            },
            interval ? interval : 1500
        );
    }
}