import {TTag} from "../../../../../model/api/TagApi";
import m from "mithril";
import TagsAssignmentContainer from "./mithrilComponents/TagsAssignmentContainer";
import TagComponentAdapter from "../../common/js/TagComponentAdapter";

export default class TagsAssignment extends TagComponentAdapter {

    protected findTagsInComponent(): TTag[] {
        const tags: TTag[] = [];
        this.$component.find('[data-tag-name]').each( (i,tagEl) => {
            const $tagEl = $(tagEl);
            tags.push({
                id: $tagEl.data('tag-id') ?? undefined,
                name: $tagEl.data('tag-name') ?? $tagEl.text(),
                htmlClass: $tagEl.data('tag-html-class') ?? '',
                isPrivate: Boolean($tagEl.data('tag-is-private')),
                deletable: this.options.editable ?? false,
                editable: this.options.editable ?? false,
                checkDuplicity: false,
                checkBlacklist: false,
            });
        });

        return tags;
    }

    public init() {
        const tags = this.findTagsInComponent();
        m.mount(this.$component[0], new TagsAssignmentContainer(tags, {
            editable: Boolean(this.options.editable),
            searchTarget: this.options.searchTarget ?? '',
            searchPrefix: this.options.searchPrefix ?? '!',
            projectId: this.options.projectId ?? null,
            getConfig: this.getConfig,
            newTagUrl: this.options.newTagUrl ?? null,
        }));
    }

}
