export function match(needle: string, haystack: string): boolean {
    needle = needle.toLowerCase().trim();
    if (!needle) {
        return true;
    }
    if (typeof String.prototype.normalize === "function") {
        // remove diacritics
        needle = needle.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        haystack = haystack.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }

    return haystack.includes(needle);
}
