import IExtension from './IExtension';
import { Naja } from 'naja';

export default class FormLoad implements IExtension {

    initialize (naja: Naja): void {
        this.applyFormLoad();

        naja.addEventListener('complete', () => {
            this.applyFormLoad();
        });
    }

    private applyFormLoad () {
        $('form [data-load]').each(function () {
            const target = $(this).data('load');

            $(target).on('change', () => {
                $(this).trigger('click');
            });

            $(this).hide();
        });
    }
}
