import m, {Vnode} from "mithril";

export default class Paginator<T> {

    private self;
    private items: T[] = [];

    /** Zero-based page index */
    private page: number = 0;

    constructor(
        private readonly perPage: number = 20,
    ) {
        this.self = this;
    }

    public getMaxPageNumber(): number {
        return Math.floor(this.items.length / this.perPage)
    }

    /**
     * Set items which would be paginated
     * @param items
     */
    public setItems(items: T[]): Paginator<T> {
        this.items = items;
        this.setPage(this.page);

        return this;
    }

    /**
     * Set zero-based page number
     * @param page
     */
    public setPage(page: number): Paginator<T> {
        this.self.page = Math.max(Math.min(page, this.getMaxPageNumber()), 0);

        return this;
    }

    /**
     * Get zero-based page number
     * @return number
     */
    public getPage(): number {
        return this.page;
    }

    public hasPrevPage(): boolean {
        return this.page > 0
    }

    public hasNextPage(): boolean {
        return this.page < this.getMaxPageNumber();
    }

    public prevPage(): Paginator<T> {
        this.setPage(this.page-1);

        return this;
    }

    public nextPage(): Paginator<T> {
        this.setPage(this.page+1);

        return this;
    }

    public getPrevPagesNumber(limit = 3): number[] {
        const pages = [];
        for (let page= this.page-1; page >= 0 && page >= page-limit; page--) {
            pages.push(page);
        }
        return pages.reverse();
    }

    public getNextPagesNumber(limit = 3): number[] {
        const pages = [];
        const lastPage = this.getMaxPageNumber();
        for (let page= this.page+1; page <= lastPage && page <= page+limit; page++) {
            pages.push(page);
        }
        return pages;
    }

    public getPageOfItems(): T[] {
        return this.items.slice( this.page*this.perPage, (this.page+1)*this.perPage );
    }

    public view(): Vnode {
        return m('.Paginator.d-flex', {}, [
            m('.d-flex.flex-grow-1.justify-content-center', {}, [
                this.hasPrevPage() ? m('a.mx-2.cursor-pointer', {
                    onclick: () => this.prevPage(),
                }, '«') : m('.mx-2', '«'),
                ...this.getPrevPagesNumber().map(page => {
                    return m('a.mx-2.cursor-pointer', {
                        onclick: () => this.setPage(page),
                    }, ''+(page+1))
                }),
                m('.mx-2', {}, ''+(this.page+1)),
                ...this.getNextPagesNumber().map(page => {
                    return m('a.mx-2.cursor-pointer', {
                        onclick: () => this.setPage(page),
                    }, ''+(page+1))
                }),
                this.hasNextPage() ? m('a.mx-2.cursor-pointer', {
                    onclick: () => this.nextPage(),
                }, '»') : m('.mx-2', '»'),
            ]),

            m('.', {}, Math.min(this.perPage, this.items.length)  +'/'+ this.items.length)
        ]);
    }
}