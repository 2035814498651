import ClassicExtension from "./ClassicExtension";
import 'jquery-ui-bundle';
const i18n_cs = require('jquery-ui/ui/i18n/datepicker-cs');
import {Naja} from "naja";

export default class Datepicker extends ClassicExtension {

	private static i18n(): void {
		if ($('html').attr('lang') === 'cs') {
			$.datepicker.setDefaults(i18n_cs);
		}
	}

	initialize(naja: Naja) {
		super.initialize(naja);
		Datepicker.i18n();
	}

	protected attach($root: JQuery): void {
		$root.find('.datepicker:not(.hasDatepicker)').datepicker();
	}
}
