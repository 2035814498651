import BaseComponent from '../../BaseComponent';
import EasyMDE from "easymde";

require('easymde/dist/easymde.min.css');

export default class MarkdownEditor extends BaseComponent {

    public editor: EasyMDE;

    public init (): void {
        let element: HTMLElement = this.$component[0];

        if (!(element instanceof HTMLTextAreaElement)) {
            throw new Error('Element is not a textarea');
        }

        this.editor = new EasyMDE({
            element: element,
            toolbar: [
                "bold", "italic", "strikethrough",
                "|",
                "heading-1", "heading-2", "heading-3", "heading-bigger", "heading-smaller",
                "|",
                "ordered-list", "unordered-list",
                "|",
                "quote", "code", "horizontal-rule",
                "|",
                "link", "image",
                "|",
                "undo", "redo",
                "|",
                "preview", "guide",
            ],
            spellChecker: false,
            nativeSpellcheck: false,
        });
    }
}
