// @ts-ignore
import CodeMirror from 'codemirror';
import IEditor from "./IEditor";

require("codemirror/mode/css/css");
require("codemirror/addon/hint/show-hint");
require("codemirror/addon/hint/css-hint");

export default class CssEditor implements IEditor {
    public onChange?: ()=>void;
    public editor: CodeMirror;

    constructor(
        public readonly element: HTMLTextAreaElement
    ) {
        this.init();
    }

    private init(): void {
        this.editor = CodeMirror.fromTextArea(this.element, {
            extraKeys: {
                "Ctrl-Space": "autocomplete",
            },
            lineNumbers: true,
            mode: "css",
            lineWrapping: false,
            matchClosing: true,
            viewportMargin: Infinity,
            readOnly: this.element.hasAttribute('readonly'),
        })

        const elementJQuery = $(this.element);
        const height = elementJQuery.data('editor-height');

        this.editor.setSize("100%", height ? height : 300);
        this.editor.on('change', () => this.onChange?.());
    }
}
