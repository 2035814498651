import IExtension from './IExtension';
import { Naja } from 'naja';

export default class ClickTarget implements IExtension {
    initialize (naja: Naja): void {
        $('[data-click-target]').on('click', (e) => {
            e.preventDefault();
            const targetSelector = $(e.currentTarget).data('click-target');
            if (targetSelector) {
                $(targetSelector)[0]?.click();
            }
        })
    }
}
