import 'select2/dist/js/select2';

import ClassicExtension from "../../ClassicExtension";

export default class Select2 extends ClassicExtension {
    constructor (
        public theme: string = 'default'
    ) {
		super();
        this.theme = theme;
    }

	attach($root: JQuery): void {
        const self = this;
        $root.find('select:not(.select2-disable)').each(function () {
            self.applyToElement($(this), $(this).children('option').length > 5);
        });
        this.applyToElement($('[data-provider="select2"]').not('.select2-hidden-accessible'));
    }

    private applyToElement ($element: JQuery<HTMLElement>, suggestion: boolean = true) {
        $element.select2({
            closeOnSelect: true,
            theme: this.theme,
            tags: false,
            minimumResultsForSearch: suggestion ? 0 : Infinity, // https://select2.org/searching#hiding-the-search-box
            width: 'style',
            placeholder: $(this).data('placeholder'), // important, select2 does not handle default select value
        }).on('select2:select', () => {
            const $form = $element.closest('form');

            // @ts-ignore
            window.Nette?.toggleForm($form[0], $element[0]); // notice nette about change https://forum.nette.org/cs/32261-nette-forms-toggle-a-kniznica-select2-neodchyti-change-event

            // setTimeout(() => $element.trigger('focus'), 16); // trigger "focus" to notice LiveForms about changes (wierd, I know)
        }).on('select2:open', () => {
            setTimeout(() => {
                const searchField = <HTMLInputElement>document.querySelector(".select2-container--open .select2-search__field");
                searchField?.focus();
            }, 16);
        });
    }
}
