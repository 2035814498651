import { LiveForm, Nette } from 'live-form-validation';

LiveForm.setOptions({
    messageErrorPrefix: '',
    controlErrorClass: 'is-invalid',
    messageParentClass: 'has-error',
    showMessageClassOnParent: 'input',
    messageTag: 'div',
    messageErrorClass: 'invalid-feedback',
    showAllErrors: true
});

LiveForm.getMessageElement = function (el) {
    // For multi elements (with same name) work only with first element attributes
    if (el.name && el.name.match(/\[\]$/)) {
        el = el.form.elements[el.name].tagName ? el : el.form.elements[el.name][0];
    }
    let id = el.getAttribute('data-lfv-message-id');
    if (!id) {
        // ID is not specified yet, let's create a new one
        id = this.getMessageId(el);
        // Remember this id for next use
        el.setAttribute('data-lfv-message-id', id);
    }
    let messageEl = document.getElementById(id);

    if (!messageEl) {
        // Message element doesn't exist, lets create a new one
        messageEl = document.createElement(this.options.messageTag);
        messageEl.id = id;

        if (el.style.display === 'none' && !this.hasClass(el, this.options.enableHiddenMessageClass)) {
            messageEl.style.display = 'none';
        }

        const parentEl = this.getMessageParent(el);

        if (parentEl === el.parentNode) {
            parentEl.appendChild(messageEl); // Here is replaced insertBefore method for appendChild for correct error message display
        } else if (parentEl) {
            typeof parentEl.append === 'function' ? parentEl.append(messageEl) : parentEl.appendChild(messageEl);
        }
    }

    return messageEl;
};

window.Nette = Nette;
window.LiveForm = LiveForm;

export default LiveForm;
