import DashboardState from "../model/DashboardState";
import IDashboardComponent from "./IDashboardComponent";

export default class DataRefresh implements IDashboardComponent{
    public static readonly REFRESH_PERIOD_MS = 180_000;

    private lastStart?: number = null;
    private animationId?: number;

    constructor(
        private readonly element: HTMLElement,
        private readonly dashboardState: DashboardState
    ) {
        this.element.addEventListener('click', (e) => {
            e.preventDefault();
            window.cancelAnimationFrame(this.animationId);
            this.animationRotate();
            this.dashboardState.update();
        });
        this.element.style.willChange = "background";
    }

    update(): void {
        this.startAnimation()
    }

    name(): string {
        return this.element.dataset.dashboardComponent || '';
    }

    private startAnimation() {
        this.lastStart = null;
        window.cancelAnimationFrame(this.animationId);
        this.animationId = window.requestAnimationFrame(t => this.animate(t));
    }

    private animate(timestamp): void {
        if (!this.lastStart) {
            this.lastStart = timestamp;
        }
        const progress = (timestamp - this.lastStart) / DataRefresh.REFRESH_PERIOD_MS;
        this.element.style.background = `radial-gradient(closest-side, white 87%, transparent 90% 97%, white 100%), conic-gradient(rgba(200,200,200,${Math.min(0.1+progress*progress*1.3,1)}) ${progress*100}%, transparent ${progress*100+1}%)`

        if (progress > 1) {
            this.animationRotate();
            this.dashboardState.update();
            window.cancelAnimationFrame(this.animationId);
        } else {
            this.animationId = window.requestAnimationFrame((t) => this.animate(t));
        }
    }

    private animationRotate(): void {
        this.element.classList.remove('animation-turn');
        this.element.style.background = '';
        requestAnimationFrame(() => this.element.classList.add('animation-turn'))
    }


}