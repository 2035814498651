import BaseComponent from "../../BaseComponent";
import CodeMirror from "../../CodeMirror";

export default class Appearance extends BaseComponent {
    init(): void {
        this.$component.find('[data-end-page-template]')
            .on('change', (e) => this.onChangePageTemplate(e))
            .trigger('change');

    }

    private onChangePageTemplate(e) {
        const $customEndPageEditor = this.$component.find('[data-custom-end-page-editor]');
        if ($(e.currentTarget).val() === 'custom') {
            $customEndPageEditor.show();
            const $textarea = $customEndPageEditor.find('textarea.CodeMirror').first(); // textarea for code
            const $codeMirror = $customEndPageEditor.find('div.CodeMirror').first(); // CodeMirrored element
            if ($textarea.length && !$codeMirror.length) {
                (new CodeMirror($textarea)).init();
            }
        } else {
            $customEndPageEditor.hide();
        }
    }

}
