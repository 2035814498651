import {TDashboardData} from "../model/TDashboardData";
import IDashboardComponent from "./IDashboardComponent";

export default class QuestionnaireLast implements IDashboardComponent {

    constructor(
        private element: HTMLElement
    ) {
    }

    name(): string {
        return this.element.dataset.dashboardComponent || '';
    }

    update(state: TDashboardData) {
        const data = state["questionnaire.last"].data;
        const startTime = data.startTime ? data.startTime : '-';

        this.element.innerHTML = `<span class="value">${startTime}</span>`;
    }
}