export default class ChartHelper {
    public static readonly colorPalette = [
        '#1964AA',
        '#E67E22',
        '#009FE3',
        '#D64292',
        '#8963AB',
        '#E74C3C',
        '#EDB937',
        '#66B536',
        '#17ADA4',
    ];

    static getColorByStatus(status: string): string {
        return {
            FINISHED: '#5b9932',
            IN_PROGRESS: '#aae88b',
            QUOTA_FULL: '#e69147',
            HARDSCREENOUT: '#E74C3C',
            QCOUT: '#222222',
        }[status] ?? '#577091';
    }

    static getColorByName(name: string): string {
        let colors = {
            'info': '#5A91DC',
            'warning': '#e69147',
            'danger': '#E74C3C',
        };

        return colors[name] ?? colors['info'];
    }

    static getStatusOrder(status: string): number {
        return [
            'IN_PROGRESS',
            'QUOTA_FULL',
            'HARDSCREENOUT',
            'QCOUT',
            'FINISHED',
        ].indexOf(status);
    }

    static getColorByIndex(index: number): string {
        return this.colorPalette[index % this.colorPalette.length];
    }
}
