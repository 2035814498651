import DashboardApi from "./DashboardApi";
import {TDashboardData, TDataOptions} from "./TDashboardData";

type TListener = (state: TDashboardData, instance: DashboardState, data: any)=>any;

export default class DashboardState {
    private static instances: {[url: string]: DashboardState} = {};
    public static getInstance(apiUrl: string): DashboardState {
      if (!this.instances[apiUrl]) {
          this.instances[apiUrl] = new DashboardState(apiUrl);
      }
      return this.instances[apiUrl];
    }

    public state: TDashboardData = {};
    public readonly options: TDataOptions = {};
    private readonly api: DashboardApi;
    private readonly listeners: TListener[] = [];

    private constructor(apiUrl: string) {
        this.api = new DashboardApi(apiUrl);
    }

    public update(data?: any): void {
        this.api.getData(this.options).then((data) => {
           for (const metric in data) {
               this.state[metric] = data[metric];
           }
        }).finally(() => {
            this.listeners.forEach(l => l(this.state, this, data));
        });
    }

    public addOnUpdate(listener: TListener): void {
        this.listeners.push(listener);
    }

}
