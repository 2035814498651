import {TDashboardData} from "../model/TDashboardData";
import IDashboardComponent from "./IDashboardComponent";
import DashboardState from "../model/DashboardState";

export default class Overview implements IDashboardComponent {

    constructor(
        private element: HTMLElement,
        private dashboardState: DashboardState,
        private componentName: string,
    ) {}

    update(state: TDashboardData) {
        let componentHtml: string = '';
        const data = state["overview"].data;

        componentHtml = this.addStatuses(data, componentHtml);
        componentHtml = this.addIR(data, componentHtml);
        componentHtml = this.addLOI(data, componentHtml);

        this.element.innerHTML = componentHtml;

        this.element.getElementsByClassName('recalculate')
            .item(0)
            ?.addEventListener('click', (e) => {
                this.dashboardState.options[this.componentName] = {recalculate: {loi: true}};
                this.dashboardState.update();
                this.dashboardState.options[this.componentName] = {};
            });
    }

    name(): string {
        return this.element.dataset.dashboardComponent || '';
    }

    private addLOI(data, html: string): string {
        const loi = data['loi']['current'];
        const loiExpected = data['loi']['expected'];
        let loiExpectedHtml = '';

        if (loiExpected) {
            let className = '';
            let titleAttr = '';

            if (loiExpected['diff']) {
                const diff = loiExpected['diff'];

                titleAttr = `title="${diff['value']}"`;
                className = diff['warn'] ? 'text-danger' : 'text-success';
            }

            loiExpectedHtml = `
                <p class="small text-secondary">
                    ${loiExpected['label']}: <span ${titleAttr} class="${className}">${loiExpected['value']}</span>
                </p>`
            ;
        }

        html = html.concat(`
            <div class="results-number">
                <p class="title" title>
                    <a href="#" class='recalculate'>${loi.label}</a>
                </p>        
                <p class="value">
                    ${loi.value}
                    
                </p>        
                ${loiExpectedHtml}            
            </div>
        `);

        return html;
    }

    private addStatuses(data, html: string): string {
        for (const status of data['statuses'] ?? []) {
            const mainClass = (status.isMain ?? false) ? 'main' : '';

            html = html.concat(`
                <div class="results-number ${mainClass}">
                    <p class="title">${status.label}</p>        
                    <p class="value">${status.count}</p>        
                </div>
            `);
        }

        return html;
    }

    private addIR(data, html: string): string {
        const irRawValue = data['ir']['overall']['value'];
        const irValue = Math.round(irRawValue);

        return html.concat(`
            <div class="results-number">
                <p class="title">${data['ir']['overall']['label']}</p>        
                <p class="value">${irValue} %</p>        
            </div>
        `);
    }
}