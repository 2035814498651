import IDashboardComponent from "./IDashboardComponent";
import {TDashboardData} from "../model/TDashboardData";
import {Chart, SeriesColumnOptions} from "highcharts";
import ChartHelper from "../model/ChartHelper";

export default class QuestionsThroughputStatuses implements IDashboardComponent {

    constructor(
        private element: HTMLElement
    ) { }

    private chart?: Chart;

    name(): string {
        return this.element.dataset.dashboardComponent || '';
    }

    update(state: TDashboardData) {

        let series: SeriesColumnOptions[] = []
        state["questions.throughput.statuses"].data?.forEach?.( (bar,questionIndex) => {
            bar.data?.forEach?.((point, datasetIndex) => {

                const serie = series[datasetIndex] ?? {
                    name: point.label,
                    type: "column",
                    showInLegend: false,
                    data: [],
                };

                serie.data[questionIndex] = {
                    name: bar.label,
                    y: point.count,
                    color: ChartHelper.getColorByStatus(point.label),
                }

                series[datasetIndex] = serie;
            })
        });
        series = series.sort((a,b) => ChartHelper.getStatusOrder(a.name) - ChartHelper.getStatusOrder(b.name));

        this.chart?.destroy();
        this.chart = new Chart(this.element, {
            chart: {
                backgroundColor: "#FDFDFD",
                height: 200,
                marginTop: 0,
                marginLeft: 0,
                marginRight: 0,
                zooming: {
                    type: 'xy'
                },
            },
            title: {
                text: undefined,
            },
            xAxis: [{
                type: "category",
                title: {
                    text: undefined,
                },
                labels: {
                    formatter: function () {
                        return ''+this.value;
                    },
                    style: {
                        color: '#909090',
                    }
                },
                gridLineWidth: 0,
                lineWidth: 0,
                minorGridLineWidth: 0,
                lineColor: 'transparent',
            }],
            yAxis: [{
                visible: false,
                min: 0,
                softMax: 1,
            }],
            tooltip: {
                positioner: function () {
                    return {
                        x: this.chart.plotLeft,
                        y: this.chart.plotTop
                    }
                },
                shape: "square",
                useHTML: true,
                shadow: {
                    color: "rgba(0,0,0,0.1)",
                    offsetX: 0,
                    offsetY: 2,
                    width: 4
                },
                borderWidth: 0,
                padding: 4,
                formatter: function () {
                    const points = this.point.series.chart.series.map(s => s.points[this.point.index]);
                    return `<strong>FORM ${this.point.name}</strong><br />`
                        + points
                            .filter(p => p.y)
                            .map(p => `<span style="color:${ChartHelper.getColorByStatus(p.series.name)}">⬤</span> ${p.series.name}: <strong>${p.y}</strong><br>`)
                            .join('')
                        + `<span style="color:transparent">⬤</span> n=${points.reduce((pr,p)=>pr+p.y,0)}`;
                },
            },
            plotOptions: {
                column: {
                    stacking: 'normal'
                }
            },
            series: series,
            credits: {
                enabled: false
            },
            accessibility: {
                enabled: false
            },
        });
    }
}