export function formatTime(seconds: number, unit?: string): string {
    switch (unit) {
        case 's': return seconds + unit;
        // Add ,5 if the remainder after division is not an integer
        case 'm': return Math.floor(seconds / 60) + (seconds % 60 !== 0 ? ',5' : '') + unit;
        case 'h': return Math.floor(seconds / 3600) + (seconds % 3600 !== 0 ? ',5' : '') + unit;
        case 'd': return Math.floor(seconds / 86400) + (seconds % 86400 !== 0 ? ',5' : '') + unit;
        case 't': return Math.floor(seconds / 604800) + (seconds % 604800 !== 0 ? ',5' : '') + unit;
        case 'M': return Math.floor(seconds / 2592000) + (seconds % 2592000 !== 0 ? ',5' : '') + unit;
        case 'y': return Math.floor(seconds / 31536000) + (seconds % 31536000 !== 0 ? ',5' : '') + unit;
        default: {
            return formatTime(seconds, guessUnit(seconds));
        }
    }
}

export function guessUnit(seconds): 's'|'m'|'h'|'d'|'t'|'M'|'y' {
    if (seconds < 60) return 's';
    if (seconds < 3600) return 'm';
    if (seconds < 86400) return 'h';
    if (seconds < 604800) return 'd';
    if (seconds < 2592000) return 't';
    if (seconds < 31536000) return 'M';
    return 'y';
}