import naja from 'naja';
import IExtension from './IExtension';

export default class ExtensionRegistrar {
    private static extensions: Array<IExtension> = [];

    static addExtension (extension: IExtension): void {
        if (!this.extensions.includes(extension)) {
            this.extensions.push(extension);
        }
    }

    static register (): void {
        if (process.env.NODE_ENV === 'development') {
            console.log('Registered extensions', this.extensions);
        }

        $.each(this.extensions, (i, extension) => {
            naja.registerExtension(extension);
        });
        // clear registered extensions
        this.extensions = [];
    }
}
