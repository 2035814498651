import {Naja} from 'naja';
import IExtension from "../IExtension";

export default class DependSelectBox implements IExtension {

    initialize(naja: Naja): void {

        document.querySelectorAll('select[data-depends]').forEach((childSelect: HTMLSelectElement) => {
            let parentSelect = childSelect.form[childSelect.dataset.depends] as HTMLSelectElement;
            let url = childSelect.dataset.url;
            let items = JSON.parse(childSelect.dataset.items || 'null') as { [key: string]: string } | null;

            parentSelect.onchange = () => {
                if (items) { // Load items from data-items
                    updateSelectBox(childSelect, items[parentSelect.value]);
                }

                if (url) { // Load items from URL
                    fetch(url.replace(encodeURIComponent('#'), encodeURIComponent(parentSelect.value)))
                        .then((response) => response.json())
                        .then((data) => updateSelectBox(childSelect, data));
                }
            };
        });


        function updateSelectBox(select: HTMLSelectElement, items: { [key: string]: string }) {
            select.innerHTML = '';

            for (let id in items) {
                let el = document.createElement('option');
                el.setAttribute('value', id);
                el.innerText = items[id];
                select.appendChild(el);
            }
        }
    }

}