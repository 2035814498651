import m from "mithril";
import TagComponentAdapter from "../../common/js/TagComponentAdapter";
import TagList from "./mithrilComponents/TagList";
import TagForm from "./mithrilComponents/TagForm";
import {TTag} from "../../../../../model/api/TagApi";
import Tag from "../../common/js/Tag";

type TTaggingState = {
    tags: TTag[]|null,
    editing: TTag|null,
}

export default class TagsManagement extends TagComponentAdapter {

    private defaultHtmlClass = '';

    public readonly state: TTaggingState = {
        tags: null,
        editing: null
    }

    public edit(tag: TTag) {
        this.state.editing = tag;
        const id = tag.id ?? 'new';
        m.route.set(`/tag/${id}`);
    }

    public getDefaultHtmlClass(): string {
        return this.defaultHtmlClass;
    }

    public async init() {
        const config = await this.getConfig();

        this.defaultHtmlClass = config.tagging.htmlClassList.classes[0] ?? '';

        const newTagRoute = () => {
            const projectId = <number><unknown>m.route.param('projectId');
            const tag = Tag.createEmptyTag(m.route.param('htmlClass') ?? this.defaultHtmlClass);
            tag.name = m.route.param('name') ?? '';
            this.state.editing = tag;
            return new TagForm(this, config, projectId);
        };

        // Mount
        m.route(this.$component[0], '/list', {
            "/list": () => new TagList(this),
            "/tag/new/:projectId/:name": newTagRoute,
            "/tag/new/:projectId/:name/:htmlClass": newTagRoute,
            "/tag/:id": () => new TagForm(this, config),
        });
    }

}
