import BaseComponent from "../../BaseComponent";

export default class ShowImage extends BaseComponent {
	init() {
		this.$component.on('click', (e) => {
			e.preventDefault();
			const $el = $(e.currentTarget);
			const url = $el.attr('href');

			const $modal = $('<div style="position: fixed; top:0; left: 0; bottom: 0; right: 0; overflow: auto; text-align: center; background: rgba(0,0,0,0.4)"><span style="position: absolute; top: 0; right: 20px; color: orangered; text-shadow: 2px 4px 15px rgba(0,0,0,0.4); font-size: 50px; cursor: pointer">&times;</span><img src="' + url + '" alt="screenshot" style="max-width: 100%; margin-top: 150px"/></div>')

			$modal.on('click', function () {
				$modal.remove();
			});

			$(document.body).append($modal);
		})
	}
}
