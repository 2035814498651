import {TDashboardData} from "../model/TDashboardData";
import {Chart} from "highcharts";
import IDashboardComponent from "./IDashboardComponent";
import ChartHelper from "../model/ChartHelper";

export default class QuestionnairesStartsStatus implements IDashboardComponent{
    private chart: Chart;

    constructor(
        private element: HTMLElement
    ) { }

    name(): string {
        return this.element.dataset.dashboardComponent || '';
    }

    update(state: TDashboardData) {

        const data = state["questionnaires.starts.status"].data;
        const categories = data.map(d => d.value);
        const series = [];

        for (let status of this.getStatuses(data)) {
            series.push({
                type: "column",
                name: status,
                data: this.getDataset(data, status),
                color: ChartHelper.getColorByStatus(status),
                stack: 0,
                showInLegend: false,
                borderRadius: 2,
            });
        }

        this.chart?.destroy();
        this.chart = new Chart(this.element, {
            chart: {
                backgroundColor: "#FDFDFD",
                height: 173,
                marginTop: 0,
                marginLeft: 0,
                marginRight: 0,
            },
            title: {
                text: undefined,
            },
            xAxis: [{
                type: "category",
                title: {
                    text: undefined,
                },
                categories: categories,
                labels: {
                    rotation: 270,
                    formatter: function () {
                        return String(this.value).substring(0,10).split('-').reverse().slice(0,2).map(v => v+'.').join(' '); // 2022-09-08 00:00 => 08. 09.
                    },
                    style: {
                        color: '#909090',
                    }
                },
                gridLineWidth: 0,
                lineWidth: 0,
                minorGridLineWidth: 0,
                lineColor: 'transparent',
            }],
            yAxis: [{
                visible: false,
                min: 0,
                softMax: 1,
            }],
            tooltip: {
                positioner: function () {
                    return {
                        x: this.chart.plotLeft,
                        y: this.chart.plotTop
                    }
                },
                shape: "square",
                useHTML: true,
                shadow: {
                    color: "rgba(0,0,0,0.1)",
                    offsetX: 0,
                    offsetY: 2,
                    width: 4
                },
                borderWidth: 0,
                padding: 4,
                formatter: function () {
                    const points = this.point.series.chart.series.map(s => s.points[this.point.index]);
                    return points
                            .filter(p => p.y)
                            .map(p => `<span style="color:${ChartHelper.getColorByStatus(p.series.name)}">⬤</span> ${p.series.name}: <strong>${p.y}</strong><br>`)
                            .join('')
                        + `<span style="color:transparent">⬤</span> n=${points.reduce((pr,p)=>pr+p.y,0)}`;
                },
            },
            plotOptions: {
                column: {
                    stacking: 'normal'
                }
            },
            series: series,
            credits: {
                enabled: false
            },
            accessibility: {
                enabled: false
            },
        });
    }

    private getStatuses(data): string[] {
        const statuses: string[] = [];

        for (let currentData of data) {
            for (let dataset of currentData.data) {
                if (statuses.indexOf(dataset.value) === -1) {
                    statuses.push(dataset.value);
                }
            }
        }

        return statuses;
    }

    private getDataset(data, status: string) {
        return data.map(d => d.data.filter(p => p.value === status)[0]?.count ?? 0);
    }
}