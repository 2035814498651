import m from "mithril";
import {TDashboardData} from "./TDashboardData";

export default class DashboardApi {
    constructor(
        private readonly url: string
    ) { }

    public getData(params = {}): Promise<TDashboardData> {
        return m.request({
            method: 'POST',
            responseType: "json",
            url: this.url,
            body: params,
        });
    }
}
