import IDashboardComponent from "./IDashboardComponent";
import {TDashboardData} from "../model/TDashboardData";
import {Chart, PointOptionsObject, SeriesColumnOptions} from "highcharts";
import ChartHelper from "../model/ChartHelper";

export default class QuestionsThroughput implements IDashboardComponent {

    private chart?: Chart;

    constructor(
        private element: HTMLElement
    ) { }

    name(): string {
        return this.element.dataset.dashboardComponent || '';
    }

    update(state: TDashboardData) {

        const serie: SeriesColumnOptions = {
            name: '',
            type: "column",
            showInLegend: false,
            color: ChartHelper.getColorByName('info'),
        }
        const points: PointOptionsObject[] = [];
        state["questions.throughput"].data?.forEach?.(point => {

            points.push({
                name: point.label,
                y: point.count,
            })

        });
        serie.data = points;

        this.chart?.destroy();
        this.chart = new Chart(this.element, {
            chart: {
                backgroundColor: "#FDFDFD",
                height: 200,
                marginTop: 0,
                marginLeft: 0,
                marginRight: 0,
                zooming: {
                    type: 'xy'
                },
            },
            title: {
                text: undefined,
            },
            xAxis: [{
                type: "category",
                title: {
                    text: undefined,
                },
                labels: {
                    formatter: function () {
                        return '' + this.value;
                    },
                    style: {
                        color: '#909090',
                    }
                },
                gridLineWidth: 0,
                lineWidth: 0,
                minorGridLineWidth: 0,
                lineColor: 'transparent',
            }],
            yAxis: [{
                visible: false,
                min: 0,
                softMax: 1,
            }],
            tooltip: {
                positioner: function () {
                    return {
                        x: this.chart.plotLeft,
                        y: this.chart.plotTop
                    }
                },
                shape: "square",
                useHTML: true,
                shadow: {
                    color: "rgba(0,0,0,0.1)",
                    offsetX: 0,
                    offsetY: 2,
                    width: 4
                },
                borderWidth: 0,
                padding: 4,
                formatter: function () {
                    return `<strong>FORM ${this.point.name}</strong><br />`
                        + `n=${this.point.y}`;
                },
            },
            plotOptions: {
                column: {
                    stacking: 'normal'
                }
            },
            series: [serie],
            credits: {
                enabled: false
            },
            accessibility: {
                enabled: false
            },
        });
    }

}
