import "../../style/TagAssign.scss";

import m, {Vnode, VnodeDOM} from "mithril";
import {TTag} from "../../../../../../model/api/TagApi";
import { createPopper } from '@popperjs/core';
import translator from "../../../../../model/translator";
import TagsAssignmentContainer from "./TagsAssignmentContainer";
import api from "../../../../../../model/api";
import Tag from "../../../common/js/Tag";
import {match} from "../../../common/js/TagUtilities";

export default class TagAssign {
    private static tags?: TTag[];

    private filterState = {
        query: '',
        htmlClass: null,
    };

    constructor (
        public readonly container: TagsAssignmentContainer,
        public readonly excludedTags: TTag[],
    ) {
        this.getTags();
        m.redraw();
    }

    private async getTags() {
        if (!TagAssign.tags) {
            TagAssign.tags = await api.tag.tags(999999);
        }
        return TagAssign.tags;
    }

    private async assignTag(tag: TTag) {
        try {
            await api.tag.assign(tag.id, this.container.options.projectId);
            this.excludedTags.push(tag);
            this.container.tags.push(new Tag(tag));
        } catch (e) {
            alert('Tag assign failed.');
        }
    }


    private resetFilter(): void {
        this.filterState.query = '';
        this.filterState.htmlClass = null;
    }


    private viewCloseButton(): Vnode {
        return m('button.btn.btn-sm.btn-link', {
            tabindex: 3,
            onclick: () => this.container.closeTagAssign(),
        }, translator.translate('close'))
    }

    private viewFilterText(): Vnode {
        return m('input[type="text"]', {
            value: this.filterState.query,
            tabindex: 1,
            oninput: e => this.filterState.query = String(e.currentTarget.value).toLocaleLowerCase(),
            onkeydown: e => {
                if (e.key === 'Escape') {
                    if (e.currentTarget.value) {
                        this.resetFilter();
                        e.currentTarget.value = '';
                        e.preventDefault();
                        e.stopPropagation();
                    }
                } else if (e.key === 'Enter') {
                    e.preventDefault();
                    e.stopPropagation();
                }
            },
            oncreate: (vnode) => {
                (<HTMLInputElement>vnode.dom).focus();
            }
        });
    }

    private viewFilterColor(): Vnode {
        const tagColorsConfig = this.container.config.tagging.htmlClassList;
        const colors = [...tagColorsConfig.classes, tagColorsConfig.blacklist, tagColorsConfig.duplicity];
        return m('.tag-filter-color', {},
            colors.map(color => {
                const active = color === this.filterState.htmlClass;
                return m('.dot.'+color+(active?'.active':''), {
                    onclick: () => this.filterState.htmlClass = active ? null : color,
                });
            })
        );
    }

    private viewCreateNewTagLink(): Vnode|null {
        const htmlClass = this.container.config.tagging.htmlClassList.classes.some(c => c === this.filterState.htmlClass) ? this.filterState.htmlClass : '';
        const link = this.container.options.newTagUrl
            + '#!/tag/new'
            + ('/' + this.container.options.projectId)
            + ('/' + (this.filterState.query ? this.filterState.query : 'tag'))
            + (htmlClass ? '/' + htmlClass : '');
        return m('a.d-block.text-center.my-3.a-fade-in-up', {
            href: link,
        }, translator.translate('tag_create'))
    }

    private viewTags(tags: TTag[]): Vnode {
        return m('.tag-list', {}, tags.sort((a, b) => a.name.localeCompare(b.name))
            .map(tag => {
                return m('span', {
                    onclick: async () =>  {
                        await this.assignTag(tag);
                        m.redraw();
                    },
                }, m(new Tag(tag)));
            })
        );
    }

    private viewResults(): Vnode {
        if (!this.filterState.query && !this.filterState.htmlClass) {
            return m('div.text-center.text-muted.my-3.a-fade-in-up', {}, translator.translate('tag_start_writing_to_search_tag'))
        }

        const tags = (TagAssign.tags ?? []).filter(tag => {
            if (this.excludedTags.some(exTag => exTag.id === tag.id)) {
                return false;
            }
            if (this.filterState.htmlClass && this.filterState.htmlClass !== tag.htmlClass) {
                return false;
            }
            return match(this.filterState.query, tag.name);
        });

        if (tags.length) {
            return this.viewTags(tags);
        } else if (this.excludedTags.some(tag => tag.name === this.filterState.query)) {
            return m('div.text-center.text-muted.my-3.a-fade-in-up', {}, translator.translate('tag_already_assigned', {tag: this.filterState.query}));
        } else if (this.container.options.newTagUrl) {
            return this.viewCreateNewTagLink();
        } else {
            return m('div.text-center.text-muted.my-3.a-fade-in-up', {}, translator.translate('tag_not_found'));
        }

    }

    public view(): Vnode {
        let popper;
        return m('.TagAssign.d-flex.flex-column', {
            tabindex: -1,
            oncreate: (vnode: VnodeDOM) => {
                popper = createPopper(this.container.element, <HTMLElement>vnode.dom, {
                    strategy: "fixed",
                });
            },
            ondelete: () => {
                popper?.destroy();
            },
        }, [
            m('label.mb-0', {}, translator.translate('tags_find')),
            m('.tag-filter', {}, [
                this.viewFilterText(),
                // this.viewFilterColor(),
            ]),
            m('label.mb-0.mt-3', {}, translator.translate('tags_assign')),
            m('.tag-result.flex-grow-1', {}, [
                this.viewResults(),
            ]),
            m('.tag-controls.d-flex.flex-row-reverse', {}, [
                this.viewCloseButton(),
            ]),
        ]);
    }
}
