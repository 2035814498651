import {TDashboardData} from "../model/TDashboardData";
import {Chart} from "highcharts";
import {formatTime, guessUnit} from "../model/formatTime";
import IDashboardComponent from "./IDashboardComponent";

export default class QuestionnairesStarts implements IDashboardComponent {
    private chart: Chart;

    constructor(
        private element: HTMLElement
    ) { }

    name(): string {
        return this.element.dataset.dashboardComponent || '';
    }

    update(state: TDashboardData) {

        const data = state["questionnaires.starts"].data;
        const step = (data[0] && data[1]) ? (data[1].value - data[0].value) : 1800;
        const stepUnit = guessUnit(step);
        const categories = data.map((p,i) => (i+1)*step).reverse();
        const timeRange= Math.max( ...categories ) - Math.min( ...categories );
        const counts = data.map(p => p.count);
        const unit = guessUnit(Math.min( ...categories )*2);

        let tooltipFormatDate;

        if (timeRange < 60*60) {
            tooltipFormatDate = (d: Date) => d.toLocaleTimeString();
        } else if (timeRange < 24*60*60) {
            tooltipFormatDate = (d: Date) => d.getHours()+':'+(d.getMinutes()<10?'0':'')+d.getMinutes();
        } else if (timeRange < 30*24*60*60) {
            tooltipFormatDate = (d: Date) => d.toLocaleDateString()+' '+d.getHours()+':'+(d.getMinutes()<10?'0':'')+d.getMinutes();
        } else {
            tooltipFormatDate = (d: Date) => d.toLocaleDateString();
        }


        this.chart?.destroy();
        this.chart = new Chart(this.element, {
            chart: {
                backgroundColor: "#FDFDFD",
                height: 134,
            },
            title: {
                text: undefined,
            },
            xAxis: [{
                title: {
                    text: undefined,
                },
                categories: categories.map(c => formatTime(c, unit)),

                gridLineWidth: 0,
                lineWidth: 0,
                minorGridLineWidth: 0,
                lineColor: 'transparent',
            }],
            yAxis: [{
                visible: false,
                min: 0,
                softMax: 1,
            }],
            tooltip: {
                positioner: function() {
                    return {
                        x: this.chart.plotLeft,
                        y: this.chart.plotTop
                    }
                },
                shape: "square",
                useHTML: true,
                shadow: {
                    color: "rgba(0,0,0,0.1)",
                    offsetX: 0,
                    offsetY: 2,
                    width: 4
                },
                borderWidth: 0,
                padding: 4,
                formatter: function () {
                    const date = new Date(data[this.point.index].value*1000);
                    const count = this.point.y;
                    return `<strong>${count}</strong><br><span style="font-size:12px;line-height: 120%">${tooltipFormatDate(date)} + ${formatTime(step, stepUnit)}</span>`;
                },
            },
            plotOptions: {
                areaspline: {
                    pointPlacement: 'on',
                    lineWidth: 0,
                    lineColor: '#1964AA',
                    marker: {
                        enabled: data.length === 1,
                        fillColor: '#1964AA',
                        states: {
                            hover: {
                                lineColor: '#1964AA',
                            }
                        }
                    },
                    fillColor: {
                        linearGradient: {
                            x1: 0,
                            y1: 0,
                            x2: 0,
                            y2: 1
                        },
                        stops: [
                            [0, '#D64292'],
                            [1, '#8963AB']
                        ]
                    },
                    showInLegend: false
                }
            },
            series: [{
                type: "areaspline",
                name: "OPENED",
                data: counts,
            }],
            credits: {
                enabled: false
            },
            accessibility: {
                enabled: false
            },
        });
    }
}