// @ts-ignore
import CodeMirror from 'codemirror';
import IEditor from "./IEditor";
import mjml2html from 'mjml-browser';

export default class MjmlEditor implements IEditor {
    public editor: CodeMirror;
    private htmlTarget: JQuery<HTMLElement|HTMLTextAreaElement>;

    constructor(
        public readonly element: HTMLTextAreaElement
    ) {
        this.init();
    }

    private init(): void {
        this.editor = CodeMirror.fromTextArea(this.element, {
            extraKeys: {},
            lineNumbers: true,
            mode: "xml",
            lineWrapping: false,
            htmlMode: true,
            matchClosing: true,
            indentWithTabs: true,
            viewportMargin: Infinity,
            readOnly: this.element.hasAttribute('readonly'),
        })

        const elementJQuery = $(this.element);
        const convertTo = $(elementJQuery.data('convert-to'));
        const height = elementJQuery.data('editor-height');

        if (convertTo.length) {
            this.htmlTarget = convertTo;
        }

        this.editor.setSize("100%", height ? height : 300);
        this.editor.on('change', (editor) => {
            this.updateTarget(editor);
        });

        this.updateTarget(this.editor);
    }

    private updateTarget(editor) {
        if (this.htmlTarget) {
            const mjmlValue = editor.doc.getValue()

            this.htmlTarget.val(mjml2html(mjmlValue).html);
            this.htmlTarget.trigger('change');
        }
    }

}
