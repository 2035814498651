import IDashboardComponent from "./IDashboardComponent";
import {TDashboardData} from "../model/TDashboardData";
import {Chart, PointOptionsObject, SeriesColumnOptions} from "highcharts";
import ChartHelper from "../model/ChartHelper";

export default class QuestionsDuration implements IDashboardComponent {

    private chart?: Chart;
    private yMax = 60;
    private lastState;

    constructor(
        private element: HTMLElement
    ) {
        element.parentNode.insertBefore(this.createYMaxElement(), element.nextSibling);
    }

    private createYMaxElement(): HTMLElement {
        const container = document.createElement('div')
        container.classList.add('yMax');

        const label = document.createElement('label');
        label.append('yMax = ');

        const select = document.createElement('select')
        select.classList.add('select2-disable');
        select.options.add(new Option('1m', '60', true, true));
        select.options.add(new Option('2m', '120'));
        select.options.add(new Option('5m', '300'));
        select.options.add(new Option('max', ''));
        select.addEventListener('change', e => {
            const value = (<HTMLSelectElement>e.currentTarget).value;
            this.yMax = value ? Number(value) : null;
            if (this.lastState) {
                this.update(this.lastState)
            }
        });

        container.append(label);
        container.append(select);
        return container;
    }

    private getPointColor(value: number): string {
        if (!this.yMax || value <= this.yMax) {
            return ChartHelper.getColorByName('info');
        } else if (value < this.yMax*1.25) {
            return ChartHelper.getColorByName('warning');
        }
        return ChartHelper.getColorByName('danger');
    }

    name(): string {
        return this.element.dataset.dashboardComponent || '';
    }

    update(state: TDashboardData) {
        this.lastState = state;

        const serie: SeriesColumnOptions = {
            name: '',
            type: "column",
            showInLegend: false,
            color: ChartHelper.getColorByStatus('IN_PROGRESS'),
        }

        const points: PointOptionsObject[] = [];
        state["questions.durations"].data?.forEach?.(point => {
            points.push({
                name: point.label,
                y: point.value,
                custom: {
                    count: point.count
                },
                color: this.getPointColor(point.value)
            })
        });
        serie.data = points;

        this.chart?.destroy();
        this.chart = new Chart(this.element, {
            chart: {
                backgroundColor: "#FDFDFD",
                height: 200,
                marginTop: 0,
                marginLeft: 0,
                marginRight: 0,
                zooming: {
                    type: 'x'
                },
            },
            title: {
                text: undefined,
            },
            xAxis: [{
                type: "category",
                title: {
                    text: undefined,
                },
                labels: {
                    formatter: function () {
                        return '' + this.value;
                    },
                    style: {
                        color: '#909090',
                    }
                },
                gridLineWidth: 0,
                lineWidth: 0,
                minorGridLineWidth: 0,
                lineColor: 'transparent',
            }],
            yAxis: [{
                visible: false,
                min: 0,
                softMax: 1,
                max: this.yMax
            }],
            tooltip: {
                positioner: function () {
                    return {
                        x: this.chart.plotLeft,
                        y: this.chart.plotTop
                    }
                },
                shape: "square",
                useHTML: true,
                shadow: {
                    color: "rgba(0,0,0,0.1)",
                    offsetX: 0,
                    offsetY: 2,
                    width: 4
                },
                borderWidth: 0,
                padding: 4,
                formatter: function () {
                    return `<strong>FORM ${this.point.name}</strong><br />`
                        + `t=${this.point.y}sec<br/>`
                        + `n=${this.point.options.custom.count}`;
                },
            },
            plotOptions: {
                column: {
                    stacking: 'normal'
                }
            },
            series: [serie],
            credits: {
                enabled: false
            },
            accessibility: {
                enabled: false
            },
        });
    }

}