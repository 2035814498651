import m, {Vnode} from "mithril";
import api from "../../../../../../model/api";
import {TTag} from "../../../../../../model/api/TagApi";
import translator from "../../../../../model/translator";
import Tag from "../../../common/js/Tag";
import TagsManagement from "../TagsManagement";
import {match} from "../../../common/js/TagUtilities";
import Paginator from "../../../../common/mithrilComponents/Paginator";

export default class TagList {

    private tags?: TTag[];
    private filter: string = '';
    private readonly paginator: Paginator<TTag>;

    constructor(
        public readonly container: TagsManagement
    ) {
        this.paginator = new Paginator(20);
        this.updateTags();
    }

    public async updateTags() {
        this.tags = null;
        this.updatePaginator();
        m.redraw();

        this.tags = await api.tag.tags();
        this.updatePaginator();
        m.redraw();
    }

    private async delete(tag: TTag) {
        const message = translator.translate('are_you_sure_to_delete_something', {something: translator.translate('tag')+' "'+tag.name+'"'});
        if (confirm(message)) {
            this.tags = null;
            await api.tag.delete(tag.id);
            await this.updateTags();
        }
    }


    private updatePaginator(): void {
        const tags = this.tags
            ?.filter(tag => match(this.filter, tag.name))
            .sort((a,b) => a.name.localeCompare(b.name)) ?? [];
        this.paginator.setItems(tags);
    }

    private viewTag(tag: TTag): Vnode {
        return m(new Tag(tag));
    }

    private viewCheckBlacklist(tag: TTag): Vnode {
        return tag.checkBlacklist ? m('i.icon.icon-sm.ico-account-remove', {
            title: translator.translate('tag_check_blacklist'),
        }) : null;
    }

    private viewCheckDuplicity(tag: TTag): Vnode {
        return tag.checkDuplicity ? m('i.icon.icon-sm.ico-file-copy', {
            title: translator.translate('tag_check_duplicity'),
        }) : null;
    }

    private viewEdit(tag: TTag): Vnode {
        return tag.editable ? m('button.btn-sm.btn.btn-outline-secondary',{
            onclick: () => this.container.edit(tag),
            title: translator.translate('edit'),
        }, m('i.icon.ico-pen')) : null;
    }

    private viewDelete(tag: TTag): Vnode {
        return tag.deletable ? m('button.btn-sm.btn.btn-outline-danger',{
            onclick: async () => {
                await this.delete(tag);
            },
            title: translator.translate('delete'),
        }, m('i.icon.ico-delete.cursor-pointer')) : null;
    }

    private viewFilter(): Vnode {
        return m('input[type="text"].form-input.w-auto', {
            tabindex: 1,
            placeholder: translator.translate('tags_find'),
            value: this.filter,
            oninput: e => {
                this.filter = String(e.currentTarget.value).toLocaleLowerCase();
                this.updatePaginator();
            },
            onkeydown: e => {
                if (e.key === 'Escape') {
                    this.filter = '';
                    e.currentTarget.value = '';
                    e.preventDefault();
                    e.stopPropagation();
                }
            },
            oncreate: (vnode) => {
                (<HTMLInputElement>vnode.dom).focus();
            }
        })
    }

    private viewCreate(): Vnode {
        return m('button.btn.btn-sm.btn-success',{
            onclick: () => this.container.edit(Tag.createEmptyTag(this.container.getDefaultHtmlClass())),
        }, translator.translate('tag_create'))
    }

    public view(): Vnode {
        if (!this.tags) {
            return m('span', {}, 'Loading ...');
        }

        const tags = this.paginator.getPageOfItems();

        return m('.TagList', {}, [
            m('.d-flex.justify-content-between.px-2.mb-2', {}, [
                this.viewFilter(),
                this.viewCreate(),
            ]),
            m('table.table.table-hover.table-sm', {}, [
                m('tbody', {}, tags.map(
                    tag => m('tr', {}, [
                        m('td', {}, this.viewTag(tag) ),
                        m('td', {}, [
                            this.viewCheckBlacklist(tag),
                            this.viewCheckDuplicity(tag),
                        ]),
                        m('td', {}, this.viewEdit(tag) ),
                        m('td', {}, this.viewDelete(tag) ),
                    ]))
                ),
            ]),
            m(this.paginator),
        ]);
    }

}
