import TagsAssignment from "../../TagsAssignment";
import m from "mithril";
import TagsFavouriteContainer from "./mithrilComponents/TagsFavouriteContainer";

export default class TagsFavourite extends TagsAssignment
{
    init() {
        const tags = this.findTagsInComponent();
        m.mount(this.$component[0], new TagsFavouriteContainer(tags, {
            editable: Boolean(this.options.editable),
            searchTarget: this.options.searchTarget ?? '',
            searchPrefix: this.options.searchPrefix ?? '!',
            projectId: "favourite",
            getConfig: this.getConfig,
            newTagUrl: this.options.newTagUrl ?? null,
        }));
    }
}