import m, {Vnode} from "mithril";
import {TTag} from "../../../../../model/api/TagApi";
import translator from "../../../../model/translator";

export default class Tag {
    public static createEmpty(htmlClass = ''): Tag {
        return new Tag(this.createEmptyTag(htmlClass));
    }

    public static createEmptyTag(htmlClass = ''): TTag {
        return {
            name: '',
            htmlClass: htmlClass,
            isPrivate: true,
            checkBlacklist: false,
            checkDuplicity: false,
            deletable: true,
            editable: true,
        };
    }

    constructor(
        public readonly tag: TTag
    ) { }

    public view(): Vnode {
        return m('span.badge.badge-small.'+this.tag.htmlClass, {}, [
            this.tag.isPrivate ? m('i.icon.ico-lock.text-black-50.d-inline-block.line-height-inherit') : null,
            this.tag.name || translator.translate('tag_example')
        ]);
    }
}