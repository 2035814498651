import {TVariableStats} from "./TAnswersHistogram";
import ChartHelper from "../../../model/ChartHelper";
import {Options} from "highcharts";

export default function createChartDefinition(stats: TVariableStats, chartMax: number): Options {
    let data = stats.data ?? [];
    data = [...data];
    data.sort((a,b) => Number(a.value) - Number(b.value));
    return {
        chart: {
            type: 'bar',
            height: 20,
            margin: [0,0,0,0],
            backgroundColor: "rgba(0,0,0,0.05)",
        },
        title: {
            text: undefined,
        },
        subtitle: {
            text: undefined,
        },
        series: data.map( o => {
            return {
                type: 'column',
                name: o.label,
                data: [o.count],
                color: ChartHelper.getColorByIndex(Number(o.value)),
                custom: o,
            }
        }),
        plotOptions: {
            series: {
                stacking: 'normal',
                dataSorting: {
                    enabled: false,
                },
            },
            bar: {
                pointWidth: 20,
                pointPadding: 0,
                borderRadius: 0,
            },
            column: {
                pointWidth: 20,
                pointPadding: 0,
                borderRadius: 0,
            }
        },
        xAxis: {
            categories: ['n'],
            title: {
                text: undefined
            },
            labels: {
                enabled: false,
            },
            lineColor: 'transparent',
        },
        yAxis: [{
            max: chartMax,
            title: {
                text: undefined,
            },
            labels: {
                enabled: false,
            },
            reversedStacks: false,
            gridLineWidth: 0,
            minorGridLineWidth: 0,
            lineColor: 'transparent',
            endOnTick: false,
        }],
        tooltip: {
            animation: false,
            hideDelay: 0,
            outside: true,
            positioner: function (tooltipWidth,tooltipHeight) {
                const placeTop = this.chart.container.getBoundingClientRect().y > window.innerHeight-tooltipHeight-this.chart.plotHeight-20;

                return {
                    x: this.chart.plotLeft,
                    y: placeTop ? -tooltipHeight : this.chart.plotHeight,
                }
            },
            shape: "square",
            useHTML: true,
            shadow: {
                color: "rgba(0,0,0,0.1)",
                offsetX: 0,
                offsetY: 2,
                width: 4
            },
            borderWidth: 0,
            padding: 4,
            formatter: function () {
                const points = this.point.series.chart.series.map(s => s.points[this.point.index]);
                return points
                    .filter(p => p.y)
                    .map(p => {
                        const underline = points.length !== 1 && p === this.point;
                        const label = p.series.name?.replace(/(.{60})..+/, "$1…") ?? '';
                        return `<span style="color:${p.color}">⬤</span> <strong>${p.series.options.custom.value ?? ''}</strong> <span style="${underline?'text-decoration: underline;':''}">${label} <strong>${p.y}</strong></span><br>`
                    })
                    .join('') + `<span style="color:transparent">⬤</span> n=${points.reduce((pr,p)=>pr+p.y,0)}`;

            },
        },
        legend: {
            enabled: false,
        },
        credits: {
            enabled: false,
        }
    }

}
