import IExtension from "./IExtension";
import {Naja} from "naja";

export default class OnLoadDoAjax implements IExtension {
    initialize (naja: Naja): void {
        $('[data-on-load-do-ajax]').each((i, el) => {
            const url = $(el).data('on-load-do-ajax');
            if (url) {
                naja.makeRequest('get', url, undefined, {unique: false});
            }
        });
    }
}
