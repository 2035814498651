// @ts-ignore
import CodeMirror from 'codemirror';
import IEditor from "./IEditor";

require("codemirror/mode/javascript/javascript");
require("codemirror/addon/lint/javascript-lint");

export default class JsEditor implements IEditor {
    public onChange?: ()=>void;
    public editor: CodeMirror.Editor;

    constructor(
        public readonly element: HTMLTextAreaElement
    ) {
        this.init();
    }

    private init(): void {
        this.editor = CodeMirror.fromTextArea(this.element, {
            lineNumbers: true,
            mode: "application/javascript",
            lineWrapping: false,
            indentWithTabs: true,
            viewportMargin: Infinity,
            readOnly: this.element.hasAttribute('readonly'),
        });

        const $element = $(this.element);
        const height = $element.data('editor-height');

        this.editor.setSize("100%", height ? height : 300);
        this.editor.on('change', () => this.onChange?.());
    }
}
