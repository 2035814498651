import IDashboardComponent from "../../IDashboardComponent";
import {TDashboardData} from "../../../model/TDashboardData";
import {TFormDefinition, TVariableStats} from "./TAnswersHistogram";
import DashboardState from "../../../model/DashboardState";
import {Chart} from "highcharts";
import createChartDefinition from "./createChartDefinition";
import translator from "../../../../../../model/translator";

export default class AnswersHistogram implements IDashboardComponent {

    private readonly $component: JQuery;

    private readonly definitions: TFormDefinition[];
    private readonly definitionsById: {[id: string]: TFormDefinition} = {};

    private data: null|TVariableStats[];
    private formId: string;

    constructor(
        component: HTMLElement,
        private readonly dashboardState: DashboardState,
        private readonly componentName: string,
    ) {
        this.$component = $(component);
        try {
            this.definitions = JSON.parse(this.$component.find('script').text());
            this.definitions.forEach(f => this.definitionsById[f.id] = f);
            this.setFormId(this.definitions[0].id, false)
        } catch (err) {
            console.error('AnswersHistogram: Cannot read form definition from script tag.');
        }
    }

    name(): string {
        return this.$component.data('dashboard-component') || '';
    }

    update(state: TDashboardData): void {
        if (!this.definitions?.length) {
            this.error('Error: form definition missing');
            return;
        }

        this.data = state[this.componentName]?.data;
        this.render();
    }

    private error(message: string = 'Error.'): void {
        this.$component.html(message);
    }

    private setFormId(formId: string, triggerUpdate = true): void {
        this.formId = formId;
        const state = this.dashboardState.options[this.componentName] ?? {};
        state.form = formId;
        state.variables = this.definitionsById[this.formId].variables.map(v => v.variable);
        this.dashboardState.options[this.componentName] = state;
        if (triggerUpdate) {
            this.data = null;
            this.dashboardState.update([this.componentName]);
        }
    }

    private move(dir: 1|-1): void {
        let currentIndex = null;
        this.definitions.some((d,i) => {
            if (d.id === this.formId) {
                currentIndex = i;
                return true;
            }
            return false;
        });

        const targetIndex = currentIndex+dir;
        if (targetIndex >= 0 && targetIndex < this.definitions.length) {
            this.setFormId(this.definitions[targetIndex].id);
            this.render();
        }
    }

    private createChart(stats: TVariableStats, chartMax: number): JQuery {
        const label = stats.value === stats.label ? '' : stats.label ?? '';
        const n = stats.data.reduce((sum, curr) => sum + curr.count, 0);

        const $chart = $(`<div class="chart"><div class="chart-heading"><strong>${stats.value}</strong> (n=${n}) <span class="text-muted">${label}</span></div></div>`);
        const $body = $(`<div class="chart-body"></div>`);
        $chart.append($body);

        new Chart($body[0], createChartDefinition(stats, chartMax));

        return $chart;
    }

    private render(): void {

        // Clear
        $('body > .highcharts-tooltip-container').remove(); // fix ghost tooltips
        this.$component.html('');


        const $prev = $(`<button class="btn btn-sm btn-default"><i class="icon ico-chevron-left"></i></button>`)
            .on('click', () => this.move(-1));

        const $next = $(`<button class="btn btn-sm btn-default"><i class="icon ico-chevron-right"></i></button>`)
            .on('click', () => this.move(1));

        const $select = $(
            '<select class="flex-grow-1">'
            +this.definitions.map(f => `<option value="${f.id}" ${f.id===this.formId?'selected':''}>${f.id} ${f.question}</option>`)
            +'</select>'
        ).on('change', () => this.setFormId(''+$select.val(), true));

        const $navigation = $('<div class="d-flex justify-content-between"></div>');
        $navigation.append($prev, $select, $next);

        const $charts = $(`<div class="charts"></div>`);

        if (this.data) {
            const formVisitedCount = this.data.reduce((p,c)=>Math.max(p,c.count), 0);
            const axisMax = this.data.reduce((prevMax,variableStats) => {
                const answersCount = variableStats.data.reduce((p,c) => p+c.count, 0);
                return Math.max(prevMax,variableStats.count,answersCount);
            }, 0);
            if (typeof this.data[0]?.count === 'number') {
                $charts.append(`<hr><span><strong>FORM ${this.formId}</strong> n=${formVisitedCount}</span>`)
            }

            this.data.forEach(stats => $charts.append(this.createChart(stats, axisMax)));
        } else {
            $charts.append(translator.translate('loading'))
        }

        this.$component.append($navigation, $charts);
        $select.select2({
            width: '100%',
            dropdownCssClass: 'select2-answers-histogram-dropdown'
        });

    }

}
