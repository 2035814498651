import IDashboardComponent from "./IDashboardComponent";
import DashboardState from "../model/DashboardState";

export default class Step implements IDashboardComponent {
    constructor(
        private element: HTMLSelectElement,
        dashboardState: DashboardState,
        componentName: string,
    ) {
        element.addEventListener('change', (e) => {
            dashboardState.options[componentName] = {step: Number((<HTMLSelectElement>e.currentTarget).value)}
            dashboardState.update(['questionnaires.starts']);
        });
    }

    name(): string {
        return this.element.dataset.dashboardComponent || '';
    }

    update(): void { /* nothing needed */ }
}