import CssEditor from "./Editors/CssEditor";
import HtmlEditor from "./Editors/HtmlEditor";
import MjmlEditor from "./Editors/MjmlEditor";
import BaseComponent from "../../BaseComponent";
import JsonEditor from "./Editors/JsonEditor";
import JsEditor from "./Editors/JsEditor";

export default class CodeMirror extends BaseComponent {
    init() {
        // Do nothing if the editor's element is not visible
        if (!(this.$component.is(':visible'))) {
            return;
        }
        switch (this.$component.data('mode')) {
            case "mjml": {
                new MjmlEditor(<HTMLTextAreaElement>this.$component[0])
                break;
            }
            case "css": {
                new CssEditor(<HTMLTextAreaElement>this.$component[0])
                break;
            }
            case "json": {
                new JsonEditor(<HTMLTextAreaElement>this.$component[0])
                break;
            }
            case "js": {
                new JsEditor(<HTMLTextAreaElement>this.$component[0])
                break;
            }
            default: {
                new HtmlEditor(<HTMLTextAreaElement>this.$component[0]);
            }
        }
    }
}
