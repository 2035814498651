import BaseComponent from "../../BaseComponent";

export default class ClassToggler extends BaseComponent {
    init() {
        this.$component.on('click', () => {
            const target = this.$component.data('target');
            const from = this.$component.data('class-from');
            const to = this.$component.data('class-to');

            if (target && from && to) {
                const $target = $(target);

                if ($target.length) {
                    if ($target.hasClass(from)) {
                        $target.removeClass(from);
                        $target.addClass(to);
                    } else {
                        $target.removeClass(to);
                        $target.addClass(from);
                    }
                }
            }
            window.dispatchEvent(new Event('resize'));
        });
    }
}
