import {TDashboardData} from "../model/TDashboardData";
import {Chart} from "highcharts";
import {formatTime, guessUnit} from "../model/formatTime";
import IDashboardComponent from "./IDashboardComponent";

export default class UserBrowsers implements IDashboardComponent {

    constructor(
        private element: HTMLElement
    ) { }

    name(): string {
        return this.element.dataset.dashboardComponent || '';
    }

    update(state: TDashboardData) {
        const data = state["users.browsers"].data;
        const sum = data.reduce((prev,p) => prev+p.count, 0);
        let html = '<table class="table"><tbody>';
        html += data.map( (p,i) =>
            `<tr><td>${i+1}.</td><td>${p.value}</td><td class="text-right"><strong>${p.count}</strong></td><td class="text-right">${Math.round(p.count/sum*10000)/100} %</td></tr>`
        ).join('');
        html += '</thead></table>';

        this.element.innerHTML = html;
    }
}
