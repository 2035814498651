import m, {Vnode} from "mithril";
import {TTag, TTagsConfig} from "../../../../../../model/api/TagApi";
import api from "../../../../../../model/api";
import translator from "../../../../../model/translator";
import Tag from "../../../common/js/Tag";
import TagsManagement from "../TagsManagement";

export default class TagForm {

    private readonly tagComponent: Tag;
    private disable = false;

    constructor(
        private readonly container: TagsManagement,
        private readonly config: TTagsConfig,
        private readonly projectId?: number,
    ) {
        if (container.state.editing) {
            this.tagComponent = new Tag(container.state.editing);
        } else {
            this.tagComponent = Tag.createEmpty(this.container.getDefaultHtmlClass());
            this.container.state.editing = this.tagComponent.tag;
        }
    }

    private isNameValid(tag: TTag): boolean {
        let pattern = this.config.tagging.namePattern;
        pattern = (pattern.startsWith('^')?'':'^') + pattern + (pattern.endsWith('$')?'':'$');
        const re = new RegExp(pattern, 'g');
        return re.test(tag.name);
    }

    private async save(tag: TTag) {
        this.disable = true;
        m.redraw();
        try {
            tag = await api.tag.save(tag);
            if (this.projectId) {
                await api.tag.assign(tag.id, this.projectId);
            }
            m.route.set('/list');
        } catch (err) {
            alert(translator.translate('tag_cannot_save'));
            console.warn('ERROR tag_cannot_unlink: '+(err.response?.error ?? ''));
            this.disable = false;
        }
    }

    private cancel(): void {
        m.route.set('/list')
    }

    private setPrivate(tag: TTag, value: boolean): void {
        tag.isPrivate = value;
        if (value) {
            tag.checkBlacklist = false;
            tag.checkDuplicity = false;
            tag.htmlClass = this.config.tagging.htmlClassList.classes.includes(tag.htmlClass) ? tag.htmlClass : this.container.getDefaultHtmlClass();
        }
    }

    private setCheckDuplicity(tag: TTag, value: boolean): void {
        tag.checkDuplicity = value;
        if (value) {
            tag.isPrivate = false;
            tag.checkBlacklist = false;
            tag.htmlClass = this.config.tagging.htmlClassList.duplicity;
        } else {
            tag.htmlClass = this.container.getDefaultHtmlClass();
        }
    }

    private setCheckBlacklist(tag: TTag, value: boolean): void {
        tag.checkBlacklist = value;
        if (value) {
            tag.isPrivate = false;
            tag.checkDuplicity = false;
            tag.htmlClass = this.config.tagging.htmlClassList.blacklist;
        } else {
            tag.htmlClass = this.container.getDefaultHtmlClass();
        }
    }

    private setName(tag: TTag, value: string): void {
        tag.name = value.toLocaleLowerCase().trim();
    }


    private viewPreview(): Vnode {
        return m('.form-group.preview', {}, [
            m(this.tagComponent ?? null),
        ]);
    }

    private viewName(tag: TTag): Vnode {
        const valid = !tag.name || this.isNameValid(tag);
        return m('.form-group'+(valid?'.valid':'.invalid'), {}, [
            m('label[for="name"]', {}, [
                m('span', {}, translator.translate('tag_name')+':'),
                valid ? null : m('span.text-danger.ml-1', {}, translator.translate('allowed_symbols')+': '+this.config.tagging.namePatternDescription),
            ]),
            m('input[type="text"][name="name"][id="name"]', {
                value: tag.name,
                oninput: e => this.setName(tag, e.currentTarget.value),
                onkeydown: async e => {
                    if (e.key === 'Enter') {
                        e.preventDefault();
                        e.stopPropagation();
                        if (this.isNameValid(tag)) {
                            await this.save(tag);
                        }
                    }
                },
                oncreate: vnode => (<HTMLInputElement>vnode.dom).focus(),
            })
        ]);
    }

    private viewFlags(tag: TTag): Vnode {
        const permissions = this.config.tagging.permissions;
        const showCheckDuplicity = tag.id ? permissions.duplicity.modify : permissions.duplicity.create;
        const showCheckBlacklist = tag.id ? permissions.blacklist.modify : permissions.blacklist.create;

        return m('.form-group', {}, [
            m('label', {}, [
                m('span', {}, translator.translate('tag_flags')+':'),
            ]),
            m('.', {}, [
                this.viewFlagCheckbox('tag_private', tag.isPrivate, e => this.setPrivate(tag, e.currentTarget.checked)),
                showCheckDuplicity ? this.viewFlagCheckbox('tag_check_duplicity', tag.checkDuplicity,e => this.setCheckDuplicity(tag, e.currentTarget.checked)) : null,
                showCheckBlacklist ? this.viewFlagCheckbox('tag_check_blacklist', tag.checkBlacklist,e => this.setCheckBlacklist(tag, e.currentTarget.checked)) : null,
            ]),
        ]);
    }

    private viewFlagCheckbox(name: string, checked: boolean, onchange): Vnode {
        return m('.', {}, [
            m(`label[for="${name}"].d-inline-flex.align-items-center`, {}, [
                m(`input[type="checkbox"][name="${name}"][id="${name}"]`, {
                    checked: checked,
                    onchange: onchange,
                }),
                m('span', {}, translator.translate(name)),
                m(`i.icon-sm.icon.ico-inform-outline`, {
                    title: translator.translate(`${name}_hint`),
                    oncreate: vnode => $(vnode.dom).tooltip(),
                }),
            ]),
        ])

    }

    private getAvailableTagHtmlClasses(tag: TTag): string[] {
        const configClasses = this.config.tagging.htmlClassList;
        const classes: string[] = [];
        if (tag.checkBlacklist) {
            classes.push(configClasses.blacklist);
        }
        if (tag.checkDuplicity) {
            classes.push(configClasses.duplicity);
        }
        if (tag.checkBlacklist || tag.checkDuplicity) {
            return classes;
        }

        classes.push(... configClasses.classes);
        return classes;
    }

    private viewColors(tag: TTag): Vnode {
        const classes = this.getAvailableTagHtmlClasses(tag);
        return m('.colorPicker', {}, [
            classes.reverse().map(htmlClass =>
                m(`.dot.cursor-pointer.${htmlClass}${htmlClass === tag.htmlClass ? '.active' : ''}`, {
                    onclick: () => tag.htmlClass = htmlClass,
                }),
            )
        ]);
    }

    private viewControls(tag: TTag): Vnode {
        const valid = this.isNameValid(tag);
        return m('.form-group.d-flex.justify-content-end', {}, [
            m('button.btn.btn-default', {
                onclick: e => {
                    e.preventDefault();
                    this.cancel();
                }
            }, translator.translate('cancel')),
            m('button.btn.btn-primary', {
                disabled: !valid,
                onclick: async e => {
                    e.preventDefault();
                    await this.save(tag);
                }
            }, translator.translate(this.projectId ? 'save_and_assign' : 'save')),
        ]);
    }

    public view(): Vnode|null {
        const tag = this.container.state.editing;
        if (!tag) {
            return null;
        }
        return m(`form.TagForm${this.disable?'.disable':''}`, {}, [
            this.viewColors(tag),
            this.viewPreview(),
            this.viewName(tag),
            this.viewFlags(tag),
            m('hr'),
            this.viewControls(tag),
        ]);
    }


}