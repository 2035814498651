export default class Translator {
    // eslint-disable-next-line no-useless-constructor
    constructor (public readonly translations: {[key: string]: string}) {}

    public get locale (): string {
        return document.querySelector('html').getAttribute('lang');
    }

    public translate (key: string): string {
        return this.translations[key] || key;
    }
}
