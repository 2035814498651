import IComponent from './IComponent';
import naja, { Naja } from 'naja';
import ClassicExtension from "../extensions/ClassicExtension";

export type ComponentsType = {[componentName: string]: new ($component: JQuery<HTMLElement>, context?: any) => IComponent};

// This factory is naja extension
export class ComponentFactory extends ClassicExtension {

	private readonly context: {naja?: Naja} = {};

    constructor (
        private readonly components: ComponentsType
    ) {
		super();
		this.context.naja = naja;
    }


	initialize(naja: Naja) {
		super.initialize(naja);
		if (process.env.NODE_ENV === 'development') {
			console.log(`Registered components (${Object.keys(this.components).length})`, this.components);
		}
	}

	protected attach($root: JQuery): void {
		this.initComponents($root);
	}

    private initComponents ($root: JQuery<HTMLElement|Element>) {

        const $components = $root.find('[data-component]');
        if ($root.is('[data-component]')) {
            $components.add(<JQuery<HTMLElement>>$root);
        }
        $.each($components, (i, componentEl) => {
            const $component = $(componentEl);
            const name = $component.data('component');

            if (name && this.components[name]) {
                // JS component was found, try to initialize, skip on error
                try {
                    (new this.components[name]($component, this.context)).init();
                    ComponentFactory.success(name, componentEl);
                    $component.removeAttr('data-component');
                } catch (err) {
                    console.error(`Component "${name}" failed to initialize due error. Skipped.\n`, err);
                }
            } else {
                // JS component is missing
                ComponentFactory.warn(name, componentEl);
            }
        });
    }

    private static warn (name, componentEl): void {
        if (process.env.NODE_ENV !== 'production') {
            console.warn(`Component '${name}' does not exist. Did you register the component for the element 👇 ?`);
            console.log(componentEl);
        }
    }

    private static success (name, componentEl): void {
        if (process.env.NODE_ENV !== 'production') {
            console.log(`✅ ${name}`, componentEl);
        }
    }
}
