import m, {Vnode} from "mithril";
import TagsAssignmentContainer from "../../../TagsAssignment/js/mithrilComponents/TagsAssignmentContainer";
import translator from "../../../../../model/translator";

export default class TagsFavouriteContainer extends TagsAssignmentContainer {

    protected viewPlusAssignTag(): Vnode|null {
        if (!this.options.editable || !this.options.projectId) {
            return null;
        }

        const showHint = !this.tags?.length && !this.tagAssign;

        return m('.d-inline-flex.text-warning.tag-add-button' + (this.loadingAssign ? '.disabled' : '') + (this.tagAssign ? '.active' : ''), {
            title: translator.translate('tag_favourite_add'),
            onclick: async e => {
                e.preventDefault();
                if (this.tagAssign) {
                    this.closeTagAssign();
                } else {
                    await this.openTagAssign();
                }
                m.redraw();
            },
        }, [
            this.loadingAssign ? [
                m('i.spinner-border.spinner-border-sm')
            ] : [
                m('i.icon.ico-star'),
                showHint ? m('span.text-muted.font-italic.ml-2.a-fade-in', translator.translate('tag_favourite_add')) : null,
            ],
        ]);
    }

    public view(): Vnode {
        const view = super.view();
        view.attrs['className'] += ' TagsFavouriteContainer';

        return view;
    }

}
