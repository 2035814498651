import {TDashboardData} from "../model/TDashboardData";
import {Chart} from "highcharts";
import IDashboardComponent from "./IDashboardComponent";

export default class UserDevices implements IDashboardComponent {
    private static readonly COLOR_DESKTOP = '#003B7A';
    private static readonly COLOR_MOBILE = '#5A91DC';

    private chart: Chart;
    private readonly percentEl: HTMLElement;

    constructor(
        private element: HTMLElement
    ) {
        this.percentEl = document.createElement('div');
        this.percentEl.classList.add('d-flex', 'justify-content-around', 'w-100');
        this.element.parentElement.append(this.percentEl);
    }

    name(): string {
        return this.element.dataset.dashboardComponent || '';
    }

    update(state: TDashboardData) {

        const data = state["users.devices"].data;

        this.updateChart(data);
        this.updatePercentEl(data);
    }

    private updateChart(data): void {
        this.chart?.destroy();
        this.chart = new Chart(this.element, {
            chart: {
                backgroundColor: "#FDFDFD",
                height: 175,
                margin: 0,
            },
            title: {
                text: undefined,
            },
            series: [{
                innerSize: '81%',
                type: "pie",
                name: "Users devices",
                data: data.map((p) => {
                    const color = p.value === 'mobile' ? UserDevices.COLOR_MOBILE : p.value === 'desktop' ? UserDevices.COLOR_DESKTOP : undefined;
                    return {
                        name: p.value,
                        y: p.count,
                        htmlClass: color,
                        color: color,
                    };
                }),
                dataLabels: {
                    enabled: false
                },
            }],
            credits: {
                enabled: false
            },
            accessibility: {
                enabled: false
            },
        });
    }

    private updatePercentEl(data: {value:string, count: number}[]): void {
        const sum = data.reduce((prev,c)=>prev+c.count, 0) || 1;
        const mobileCount = data.filter(p => p.value === 'mobile')[0]?.count ?? 0;
        const desktopCount = data.filter(p => p.value === 'desktop')[0]?.count ?? 0;

        const mobilePercent = (Math.round(mobileCount/sum*1000)/10).toLocaleString();
        const desktopPercent = (Math.round(desktopCount/sum*1000)/10).toLocaleString();

        this.percentEl.innerHTML = `
            <div class="d-flex align-items-center">
                <i class="icon ico-desktop-mac" style="color:${UserDevices.COLOR_DESKTOP}"></i>${desktopPercent} %
            </div>
            <div class="d-flex align-items-center">
                <i class="icon ico-cellphone" style="color:${UserDevices.COLOR_MOBILE}"></i>${mobilePercent} %
            </div>
        `;
    }

}
