/*eslint-disable */
import IComponent, {ComponentContext} from './IComponent';
import Translator from './Translator';

export default abstract class BaseComponent implements IComponent {
    protected readonly translator: Translator;
    protected readonly options: null|{[property: string]: any} = null;

    constructor (
        public readonly $component: JQuery<HTMLElement>,
        public readonly context?: ComponentContext,
    ) {
        this.translator = new Translator(this.readAttribute('translations'));
        this.options = this.$component.data('options'); // JSON parsed automatically by jQuery, thanks ;)
    }

    protected readAttribute(name): any
    {
        const attribute = this.$component.data(name);
        this.$component.removeAttr('data-' + name);
        return attribute;
    }

    public abstract init(): void;
}
/* eslint-enable */
