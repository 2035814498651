import IExtension from "./IExtension";
import {Naja} from "naja";

/**
 * Classic extension is applied to specific kind of elements to change their behavior
 * it also applies these changes on AJAX loaded content
 */
export default abstract class ClassicExtension implements IExtension {
	initialize(naja: Naja) {
		this.attach($('body'));
		naja.snippetHandler.addEventListener('afterUpdate', event => this.attach( <JQuery<HTMLElement>>$(event.detail.snippet)) );
	}

	/**
	 * This method is called after page or ajax content is loaded
	 * @param $root and root of just loaded content
	 */
	protected abstract attach($root: JQuery): void;
}
