import DashboardState from "../model/DashboardState";
import IDashboardComponent from "./IDashboardComponent";
import {TDashboardData} from "../model/TDashboardData";

export default class AnswersHistogramStatusSwitcher implements IDashboardComponent{

    private $select: JQuery<HTMLSelectElement>

    constructor(
        private readonly element: HTMLSelectElement,
        private readonly dashboardState: DashboardState
    ) {
        this.$select = $(element);


        const state = this.dashboardState.options['question.answers.histogram'] ?? {
            statuses: null,
            variables: null,
            form: null,
        };
        state.statuses = this.readValue();

        this.dashboardState.options['question.answers.histogram'] = state;

        this.$select.select2({
            closeOnSelect: false,
            width: 'style',
        }).on('change', (e) => {
            this.dashboardState.options['question.answers.histogram'].statuses = this.readValue();
            this.dashboardState.update(['question.answers.histogram']);
        });
    }

    private readValue(): string[] {
        const value = this.$select.val();
        return Array.isArray(value) ? value : [String(value)];
    }

    name(): string {
        return this.element.dataset.dashboardComponent || '';
    }

    update(state: TDashboardData) {
        // I decide to react not
    }



}