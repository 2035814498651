import IExtension from "../../IExtension";
import {Naja} from "naja";

export default class CopyToClipboard implements IExtension {

    initialize (naja: Naja): void {

        $("[data-copy-to-clipboard]").on('click',  e => {
            e.preventDefault();
            e.stopImmediatePropagation();
            const $el = $(e.currentTarget);
            this.copyTextToClipboard($el.data('copy-to-clipboard'));

            const $icon = $el.find('.ico-clipboard-outline').addBack('.ico-clipboard-outline').first();
            if ($icon.length) {
                $icon.removeClass('ico-clipboard-outline').addClass('ico-clipboard-check');
                setTimeout(() => {
                    $icon.removeClass('ico-clipboard-check').addClass('ico-clipboard-outline');
                }, 1500);
            }
        });

    }

    /**
     * @link https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript?page=1&tab=votes#tab-top
     * @param text
     */
    private copyTextToClipboard(text) {

        const textArea = document.createElement("textarea");

        textArea.style.position = 'fixed';
        textArea.style.top = '0';
        textArea.style.left = '0';
        textArea.style.width = '2em';
        textArea.style.height = '2em';
        textArea.style.padding = '0';
        textArea.style.border = 'none';
        textArea.style.outline = 'none';
        textArea.style.boxShadow = 'none';
        textArea.style.background = 'transparent';

        textArea.value = text;
        document.body.appendChild(textArea);

        textArea.focus();
        textArea.select();

        try {
            document.execCommand('copy');
        } catch (err) {
            console.warn('Unable to copy: '+text.substr(0,300));
        }

        document.body.removeChild(textArea);
    }


}