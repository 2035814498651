import BaseComponent from "../../../BaseComponent";
import {TTagsConfig} from "../../../../../model/api/TagApi";
import api from "../../../../../model/api";

export default abstract class TagComponentAdapter extends BaseComponent {

    private static config: TTagsConfig;

    public async getConfig() {
        if (!TagComponentAdapter.config) {
            TagComponentAdapter.config = await api.tag.config();
        }
        return TagComponentAdapter.config;
    }


}